import "./about.css";
import ReactHtmlParser from "react-html-parser";

const data = [
  {
    title: "Unleashing <br> Boundless <br> Creativity",
    caption:
      "Our creations go beyond the ordinary, inspiring curiosity and igniting imagination. Meticulously crafted environments that reveal limitless possibilities.",
    img: "/image/about/a.png",
  },
  {
    title: "Harnessing <br> Human <br> Emotion",
    caption:
      "Our curated spaces inspire tranquility, excitement, and wonder. With a careful blend of light, color, and texture, we leave an enduring imprint on the hearts of our visitors.",
    img: "/image/about/b.png",
  },
  {
    title: "Crafting <br> Memorable <br> Experiences",
    caption:
      "Our spaces guide and enhance journeys, seamlessly accommodating needs and desires. With meticulous attention to flow, functionality, and purpose, each encounter becomes an opportunity for inspiration and connection.",
    img: "/image/about/c.png",
  },
];

export default function About() {
  return (
    <section id="about">
      <div className="relative">
        <div className="relative" id="about_us_height">
          <img
            src="image/about/about1.jpg"
            alt=""
            className="object-cover h-full w-full"
          />
          <div className="absolute md:top-[24%] left-1/2 top-[25%] about-header  -translate-x-[50%] -translate-y-[50%]" id="about_us_header">
            <div className="text-xs font-bold text-white work-title">
              ABOUT US
            </div>
          </div>
          <div className=" absolute md:bottom-[40%] bottom-[8%]  left-[10%] right-[10%] -translate-y-[0%] w-[80%] ipad-header">
            <h2 className="text-[#6B6B6B] font-semibold mb-3 text-left title md:text-4xl text-sm leading-[1.5rem] font-semibold ipad-text">
              Spatial design that inspires
            </h2>
            <div className="text-xs sm:text-sm md:text-xl text-[#9A948B] about-body">
              moodboard leverages the combination of creative artistry and
              scientific precision, to build invigorating environments that
              uplift and leave an indelible mark. From enchanting homes to
              awe-inspiring public spaces, our designs elevate experiences that
              linger in memory.
            </div>
          </div>
        </div>
        <div
          className="container mx-auto md:-mt-[350px] bg-transparent md:mb-3 mb-1 ipad-about-img"
          id="about_img_container"
        >
          <div
            className="caters grid grid-cols-12 gap-1 md:h-[100vh] w-full"
            id="about_content"
          >
            {data.map((item, index) => (
              <div className="caters-item col-span-4 md:m-[15px] m-[10px]">
                <img src={item.img} alt="" />
                <div className="content absolute inset-0 text-white md:px-5 px-1">
                  <div className="relative h-full flex justify-between items-center">
                    <div className="md:text-lg text-sm about-caption absolute md:top-[17%] top-[10%]">
                      {item.caption}
                    </div>
                    <div className="font-playfair text-sm md:text-4xl absolute md:bottom-[18%] bottom-2 text-center w-full">
                      <h2 className="about-title">
                        {" "}
                        {ReactHtmlParser(item.title)}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
