import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import ReactHtmlParser from "react-html-parser";
import { Fade, Bounce } from "react-awesome-reveal";

import { Carousel } from "react-responsive-carousel";
import CloseBtn from "../../img/close-btn.png";

import "./WorkModal.css";

const office = [
  // {
  //   image: "/image/works/modal/office-a.png",
  //   title: "Hey Communications!",
  //   description:
  //     "Step-forward approach in interior design, representing the agency’s creative mindset",
  // },
  {
    image: "/image/works/modal/office-b.png",
    title: "The Modern Bold & Beautiful",
    description:
      "The new-age salon that exceeds gender expectations, with class and elegance",
  },
  {
    image: "/image/works/modal/office-c2.png",
    title: "The Happy Place",
    description:
      "Happily welcoming guests into a stylish 4-star hotel with a splash of bright colors",
  },
  {
    image: "/image/works/modal/office4a.png",
    title: "Unity in Diversity",
    description: "Exuding warmth with a welcoming distinct Filipino touch",
  },
  {
    image: "/image/works/modal/office5.png",
    title: "Modern Pop in Color",
    description:
      "Vibrant and stylish office boasts of a chic lounge with brightly colored open work spaces",
  },
  {
    image: "/image/works/modal/office6a2.png",
    title: "New York Subway Styled",
    description:
      "Work “station” that transports you to your most productive state",
  },
];

const condo = [
  {
    image: "/image/works/modal/condo-a.png",
    title: "White Sanctuary",
    description:
      "Clean, simple, and modern design inspired by the Nordic Scandinavian style",
  },
  {
    image: "/image/works/modal/condo-b.png",
    title: "An Industrial Abode",
    description:
      "An open space that mixes elements from rustic and industrial styles",
  },
  {
    image: "/image/works/modal/condo-c.png",
    title: "Spruced Up Spaces",
    description:
      "An ”Instagrammable” abode that gives off Havana in the summer",
  },
  {
    image: "/image/works/modal/condo4a.png",
    title: "Modern Industrial Open Kitchen and Dining",
    description:
      "Gordon Ramsay- inspired kitchen and dining haven with a modern-rustic fusion",
  },
  {
    image: "/image/works/modal/condo5a.png",
    title: "Elegantly Breezy",
    description:
      "Welcoming and stylish marina — perfect balance of cool and cozy",
  },
  {
    image: "/image/works/modal/condo6.png",
    title: "Family Mansion",
    description:
      "Quintessential classic Mediterranean retirement home for a New Yorker",
  },
];

const event = [
  // {
  //   image: "/image/works/modal/event-c.png",
  //   title: "A Night Spent in Gold",
  //   description:
  //     "Commemorating victory with a theme that’s as shiny as the awards and recognition",
  // },
  {
    image: "/image/works/modal/event-b.png",
    title: "Glamour & Sophistication",
    description:
      "Celebrating the silver year with an elegant design, while showered with lavish decorations",
  },
  {
    image: "/image/works/modal/event-chivas.png",
    title: "Of Vibrance and Vivid Lights",
    description:
      "An atmosphere brimming with life and good vibes",
  },
  {
    image: "/image/works/modal/event-jameson.png",
    title: "Seasonally Versatile Spaces",
    description:
      "Revel in a medley of pleasant ornaments and festive spirits",
  },
  // {
  //   image: "/image/works/modal/event-a.png",
  //   title: "Of Springs and Sunflowers",
  //   description:
  //     "Spending Christmas with co-workers, in the warmth of a Vernal-themed party",
  // },
];

const customStyles = {
  content: {
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // minWidth: '768px',
    // minHeight: '90vh',
    // overflow: 'hidden',
    padding: "0",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.85)",
    zIndex: 1050,
  },
};

Modal.setAppElement("#root");

export default class WorkWithUsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: true,
      loading: false,
      selected: this.props.selected,
      formModalOpen: false,
    };
    // this.OpenModal = this.OpenModal.bind(this);
    // this.CloseModal = this.CloseModal.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);
    this.handleFormDisplay = this.handleFormDisplay.bind(this);
  }

  // componentDidMount() {
  //   this.state.selected = JSON.stringify(this.props.selected)
  //   console.log(this.state.selected)
  // }

  // OpenModal() {
  //   this.setState({modalIsOpen:true})
  // }
  // CloseModal() {
  //   this.setState({modalIsOpen: false})
  // }
  toggleLoader() {
    this.setState({ loading: this.state.loading ? false : true });
  }
  setIsFormDisplayed() {
    this.setState({ loading: this.state.loading ? false : true });
  }
  handleFormDisplay() {
    this.setState({ formModalOpen: true });
  }

  render() {
    const { ...result } = this.state.selected;

    return (
      <>
        <Modal
          isOpen={this.props.show}
          style={customStyles}
          className="max-w-[100vw] m-auto max-h-[100vh] md:absolute relative top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] md:p-10 p-6 outline-none"
        >
          <Fade>
            {result.type == "event" && (
              <div className="w-full h-full md:w-[100vw] block mx-auto bg-[#FFF3EA]">
                <div className="relative">
                  <div className="overflow-hidden h-full w-full">
                    <div className="block mx-auto md:px-16 px-8 absolute md:top-[5%] top-[8%] left-[0] z-10 text-white w-full">
                      <div className="text-xs uppercase">Our works</div>
                      <h2 className="md:text-3xl sm:text-md text-sm">
                        {result.title}
                      </h2>
                      <div className="flex justify-end md:relative h-full">
                        <button
                          className="z-20 work-modal-close-btn relative"
                          onClick={this.props.close}
                        >
                          <div className="absolute work-modal-line"></div>x
                        </button>
                      </div>

                      <div className="md:text-lg sm:text-sm text-xs">
                        {result.subtitle}
                      </div>
                    </div>
                    <Carousel
                      showArrows={false}
                      showStatus={false}
                      showThumbs={false}
                      stopOnHover={false}
                      autoPlay={true}
                      infiniteLoop={true}
                      interval={2500}
                      emulateTouch={true}
                      swipeable={true}
                    >
                      {event.map((e, i) => {
                        return (
                          <>
                            <div className="overflow-hidden">
                              <img
                                src={e.image}
                                className="md:h-[100vh] md:w-[100vw] object-cover"
                                alt=""
                              />
                            </div>
                            <div className="text-left block mx-auto md:px-16 px-8 absolute md:bottom-[5%] bottom-[12%] left-[0] text-white w-full z-10">
                              <h2 className="md:text-4xl sm:text-md text-sm">
                                {e.title}
                              </h2>
                              <div className="md:text-xl sm:text-sm text-xs">
                                {e.description}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>
            )}
            {result.type == "office" && (
              <div className="w-full h-[full] md:w-[100vw] block mx-auto bg-[#FFF3EA]">
                <div className="relative">
                  <div className="overflow-hidden h-full w-full">
                    <div className="block mx-auto md:px-16 px-8 absolute md:top-[5%] top-[8%] left-[0] z-10 text-white w-full">
                      <div className="text-xs uppercase">Our works</div>
                      <h2 className="md:text-3xl sm:text-md text-sm">
                        {result.title}
                      </h2>
                      <div className="flex justify-end md:relative h-full">
                        <button
                          className="z-20 work-modal-close-btn relative"
                          onClick={this.props.close}
                        >
                          <div className="absolute work-modal-line"></div>x
                        </button>
                      </div>

                      <div className="md:text-lg sm:text-sm text-xs">
                        {result.subtitle}
                      </div>
                    </div>

                    <Carousel
                      showArrows={false}
                      showStatus={false}
                      showThumbs={false}
                      stopOnHover={false}
                      autoPlay={true}
                      infiniteLoop={true}
                      interval={2500}
                      emulateTouch={true}
                      swipeable={true}
                    >
                      {office.map((e, i) => {
                        return (
                          <>
                            <div className="overflow-hidden">
                              <img
                                src={e.image}
                                className="md:h-[100vh] md:w-[100vw] object-cover"
                                alt=""
                              />
                            </div>
                            <div className="text-left block mx-auto md:px-16 px-8 absolute md:bottom-[5%] bottom-[12%] left-[0] text-white w-full z-10">
                              <h2 className="md:text-4xl sm:text-md text-sm">
                                {e.title}
                              </h2>
                              <div className="md:text-xl sm:text-sm text-xs">
                                {e.description}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>
            )}
            {result.type == "condo" && (
              <div className="w-full h-full md:w-[100vw] block mx-auto bg-[#FFF3EA]">
                <div className="relative">
                  <div className="overflow-hidden h-full w-full">
                    <div className="block mx-auto md:px-16 px-8 absolute md:top-[5%] top-[8%] left-[0] z-10 text-white w-full">
                      <div className="text-xs uppercase">Our works</div>
                      <h2 className="md:text-3xl sm:text-md text-sm">
                        {result.title}
                      </h2>
                      <div className="flex justify-end md:relative h-full">
                        <button
                          className="z-20 work-modal-close-btn relative"
                          onClick={this.props.close}
                        >
                          <div className="absolute work-modal-line"></div>x
                        </button>
                      </div>

                      <div className="md:text-lg sm:text-sm text-xs">
                        {result.subtitle}
                      </div>
                    </div>
                    <Carousel
                      showArrows={false}
                      showStatus={false}
                      showThumbs={false}
                      stopOnHover={false}
                      autoPlay={true}
                      infiniteLoop={true}
                      interval={2500}
                      emulateTouch={true}
                      swipeable={true}
                    >
                      {condo.map((e, i) => {
                        return (
                          <>
                            <div className="overflow-hidden">
                              <img
                                src={e.image}
                                className="md:h-[100vh] md:w-[100vw] object-cover"
                                alt=""
                              />
                            </div>
                            <div className="text-left block mx-auto md:px-16 px-8 absolute md:bottom-[5%] bottom-[12%] left-[0] text-white w-full z-10">
                              <h2 className="md:text-4xl sm:text-md text-sm">
                                {e.title}
                              </h2>
                              <div className="md:text-xl sm:text-sm text-xs">
                                {e.description}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>
            )}
          </Fade>
        </Modal>
      </>
    );
  }
}
