import "./work.css";
import { useState, useEffect, useRef } from "react";
import WorkModal from "../modal/WorkModal";

const works = [
  {
    title: "Residential Spaces",
    subtitle: "Turning livable areas into accepting homes",
    inital_image: "/image/works/condo1a.png",
    hover_image: "/image/works/condo11.png",
    type: "condo",
  },
  {
    title: "Corporate & Lifestyle Spaces",
    subtitle: "Creating productive domains that promote efficiency",
    inital_image: "/image/works/office2a.png",
    hover_image: "/image/works/office11.png",
    type: "office",
  },
  {
    title: "Ambient Spaces",
    subtitle: "Elevating events with dynamic human experiences",
    inital_image: "/image/works/event2.png",
    hover_image: "/image/works/event11.png",
    type: "event",
  },
];

const Work = (props) => {
  const { modal } = props;
  const [showHover, setShowHover] = useState(false);
  const [showModal, setShowModal] = useState();
  const [selected, setSelected] = useState();
  const clickRef = useRef([]);

  const handleClick = (e, i) => {
    var supportsTouch = "ontouchstart" in window || navigator.msMaxTouchPoints;

    if (supportsTouch) {
      clickRef.current[i].classList.add("itemHovered");
      setTimeout(() => {
        setShowModal(true);
        setSelected(works[i]);
      }, 1000);
    } else {
      setShowModal(true);
      setSelected(works[i]);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    // const el = document.querySelectorAll('.item-hover');
    clickRef.current.map((elem) => {
      // elem.style.opacity = 0;
      elem.classList.remove("itemHovered");
    });
    // el.style.opacity = 0;
  };

  if (showModal) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
  return (
    <>
      <div
        className="work-container py-3 overflow-hidden md:h-[100vh]"
        id="works"
      >
        <div className="grid grid-cols-1 h-full">
          <div className="text-center uppercase text-sm work-title">
            Our Works
          </div>
          {works.map((e, i) => {
            return (
              <div
                className="item mx-auto flex items-center h-full"
                // onClick={() => {
                //     setOpenWorkModal(true);
                // }}
                onClick={() => handleClick(e, i)}
              >
                <img
                  src={e.inital_image}
                  alt="inital image"
                  className="item-initial object-cover"
                />
                <img
                  src={e.hover_image}
                  alt="hovered image"
                  className="item-hover object-cover"
                  // style={{ opacity: showHover ? "1" : "0" }}
                  ref={(el) => (clickRef.current[i] = el)}
                />
                <div className="absolute bottom-[-3%] text-center w-full">
                  <h2 className="md:text-[17px] text-xs work-modal-title">
                    {e.title}
                  </h2>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {showModal && (
        <WorkModal
          show={showModal}
          close={handleCloseModal}
          selected={selected}
          modal={modal}
        />
      )}
    </>
  );
};

export default Work;
