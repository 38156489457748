import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import ReactHtmlParser from "react-html-parser";
import { Fade, Bounce } from "react-awesome-reveal";

import { Carousel } from "react-responsive-carousel";
import CloseBtn from "../../img/close-btn.png";

import "./WorkModal.css";

const customStyles = {
  content: {
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // minWidth: '768px',
    // minHeight: '90vh',
    // overflow: 'hidden',
    padding: "0",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.85)",
    zIndex: 1050,
  },
};

Modal.setAppElement("#root");

export default class WorkWithUsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: true,
      loading: false,
      formModalOpen: false,
    };
    // this.OpenModal = this.OpenModal.bind(this);
    // this.CloseModal = this.CloseModal.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);
    this.handleFormDisplay = this.handleFormDisplay.bind(this);
  }

  // componentDidMount() {
  //   this.state.selected = JSON.stringify(this.props.selected)
  //   console.log(this.state.selected)
  // }

  modalIsOpen = true
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "initial");

  // OpenModal() {
  //   this.setState({modalIsOpen:true})
  // }
  // CloseModal() {
  //   this.setState({modalIsOpen: false})
  // }
  toggleLoader() {
    this.setState({ loading: this.state.loading ? false : true });
  }
  setIsFormDisplayed() {
    this.setState({ loading: this.state.loading ? false : true });
  }
  handleFormDisplay() {
    this.setState({ formModalOpen: true });
  }

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.show}
          style={customStyles}
          className="bg-[#472F1E] max-w-[100vw] m-auto max-h-[100vh] md:absolute relative md:top-1/2 md:left-1/2 md:-translate-x-[50%] md:-translate-y-[50%] md:p-10 p-6 outline-none"
        >
          <div className="flex justify-end h-full">
            <button
              className="z-20 privacy-modal-close-btn relative text-white md:mt-10 mt-3 md:-mb-10 mb-0 md:mr-10 mr-3"
              onClick={this.props.close}
            >
              x
            </button>
          </div>
          <Fade>
            <div className="relative h-[100vh] w-[100vw] text-white text-sm">
              <div className="container inter-font md:pt-10 md:pb-10 pb-[150px] overflow-scroll h-full">
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    PRIVACY POLICY
                  </div>
                  <div className="privacy-content">
                    Registration data and other personally identifiable
                    information that we may collect is subject to the terms of
                    our Anti Counterfeiting Policy.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    YOUR CONDUCT
                  </div>
                  <div className="privacy-content">
                    You agree that our website may expose you to content that
                    may be objectionable or offensive. We shall not be
                    responsible to you in any way for the content that appears
                    on this website nor for any error or omission. You
                    explicitly agree, in using this website or any service
                    provided, that you shall not: (a) Provide any content or
                    perform any conduct that may be unlawful, illegal,
                    threatening, harmful, abusive, harassing, stalking,
                    tortious, defamatory, libelous, vulgar, obscene, offensive,
                    objectionable, pornographic, designed to or does interfere
                    or interrupt this website or any service provided, infected
                    with a virus or other destructive or deleterious programming
                    routine, give rise to civil or criminal liability, or which
                    may violate an applicable local, national or international
                    law; (b) Impersonate or misrepresent your association with
                    any person or entity, or forge or otherwise seek to conceal
                    or misrepresent the origin of any content provided by you;
                    (c) Collect or harvest any data about other users; (d)
                    Provide or use this website and any content in a manner that
                    would involve junk mail, spam, chain letters, pyramid
                    schemes, or any other form of unauthorized advertising
                    without our prior written consent; (e) Provide any content
                    that may give rise to our civil or criminal liability or
                    which may constitute or be considered a violation of any
                    local, national or international law, including but not
                    limited to laws relating to copyright, trademark, patent, or
                    trade secrets; (f) Infringe on any copyrights or
                    intellectual property rights; (g) Offer for sale, promote or
                    advertise through our website : weaponry, items designed to
                    restrain or hurt or harm or inconvenience people; drugs or
                    narcotics, tobacco, substances which may be used – or
                    advertised as such – to achieve hallucinogenic effects;
                    pornography, alcohol, racially offensive, or carry out,
                    promote or advertise any manner of illegal activities.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    THIRD PARTY SERVICES
                  </div>
                  <div className="privacy-content">
                    Goods and services of third parties may be advertised and/or
                    made available on or through this website. Representations
                    made regarding products and services provided by third
                    parties are governed by the policies and representations
                    made by these third parties. We shall not be liable for or
                    responsible in any manner for any of your dealings or
                    interaction with third parties.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    INDEMNIFICATION
                  </div>
                  <div className="privacy-content">
                    You agree to indemnify and hold us harmless, our
                    subsidiaries, affiliates, related parties, officers,
                    directors, employees, agents, independent contractors,
                    advertisers, partners, and co-branders from any claim or
                    demand, including reasonable legal fees, that may be made by
                    any third party, that is due to or arising out of your
                    conduct or connection with this website or its products and
                    services, your violation of these Terms and Conditions or
                    any other violation of the rights of another person or
                    party.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    DISCLAIMER OF WARRANTIES
                  </div>
                  <div className="privacy-content">
                    You understand and agree that your use of this website or
                    purchase of any of its products and services provided (The
                    “Product” or “Service”) is made available and provided to
                    your at your own risk. It is provided to you “as is” and we
                    expressly disclaim all warranties of any kind, implied or
                    expressed, including but not limited to the warranties of
                    merchantability, fitness for a particular purpose, and
                    non-infringement. We make no warranty that any part of the
                    service will be uninterrupted, error-free, virus-free,
                    timely, secure, accurate, reliable, of any quality. Some
                    jurisdictions may not allow disclaimers of implied
                    warranties and the above disclaimer may not apply to you
                    only as it relates to implied warranties.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    LIMITATION OF LIABILITY
                  </div>
                  <div className="privacy-content">
                    You expressly understand and agree that we shall not be
                    liable for any direct, indirect, special, incidental,
                    consequential or exemplary damages. In some jurisdictions,
                    it is not permitted to limit liability and therefore such
                    limitations may not apply to you.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    RESERVATION OF RIGHTS
                  </div>
                  <div className="privacy-content">
                    We reserve all of our rights, including but not limited to
                    any and all copyrights, trademarks, patents, trade secrets,
                    and any other proprietary right that we may have in our
                    website, its content, and the goods and services that may be
                    provided. The use of our rights and property requires our
                    prior written consent. We are not providing you with any
                    implied or express licenses or rights by making services
                    available to you and you will have no rights to make any
                    commercial uses of our website or service without our prior
                    written consent.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    APPLICABLE LAW
                  </div>
                  <div className="privacy-content">
                    You agree that these Terms and Conditions and any dispute
                    arising out of your use or misuse of this website or our
                    products or services shall be governed by and construed in
                    accordance with local laws where the headquarters of the
                    owner of this website is located, without regard to its
                    conflict of law provisions. By accessing this website and
                    purchasing any of its products or services, you consent and
                    submit to the exclusive jurisdiction and venue of the county
                    or city where the headquarters of the owner of this website
                    is located.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    MISCELLANEOUS INFORMATION
                  </div>
                  <div className="privacy-content">
                    (I) In the event that these Terms and Conditions conflicts
                    with any law under which any provision may be held invalid
                    by a court with jurisdiction over the parties, such
                    provision will be interpreted to reflect the original
                    intentions of the parties in accordance with applicable law,
                    and the remainder of these Terms and Conditions will remain
                    valid and intact; (II) The failure of either party to assert
                    any right under these Terms and Conditions shall not be
                    considered a waiver of any that party’s right and that right
                    will remain in full force and effect; (III) You agree that
                    without regard to any statue or contrary law that any claim
                    or cause arising out of this website or its products and
                    services must be filed within one (1) year after such claim
                    or cause arose or the claim shall be forever barred; (IV) We
                    may assign our rights and obligations under these Terms and
                    Conditions and we shall be relieved of any further
                    obligation.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">CONSENT</div>
                  <div className="privacy-content">
                    By continuing to browse or otherwise accessing the website,
                    you signal acceptance of the terms and disclaimer set out
                    above. If you do not accept any of these terms, leave this
                    website now. By logging into the Moodboard Manila Control
                    Panel, you signal that you accept these Terms & Conditions
                    in full.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    INTERPRETATION
                  </div>
                  <div className="privacy-content">
                    Where permissable by law, Moodboard Manila is the sole
                    arbiter of the meaning and context of these terms and
                    conditions.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">USAGE DATA</div>
                  <div className="privacy-content">
                    In the course of managing and optimising our website,
                    Moodboard Manila monitors the activity of this site to
                    develop better selling techniques for our clients. This
                    information resides on our servers and is never passed on to
                    a third party, and never used for marketing purposes. This
                    information is held purely to provide statistical analysis
                    and trace errors, in an effort to benefit our clients.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    SUPPORT & CONTACT DETAILS
                  </div>
                  <div className="privacy-content">
                    Your feedback is important to us, and we want to serve you
                    the best way we can. You may reach us via email at
                    inquiry@moodboard.space
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    PRIVACY POLICY
                  </div>
                  <div className="privacy-content">
                    Registration data and other personally identifiable
                    information that we may collect is subject to the terms of
                    our Anti Counterfeiting Policy.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    OUR ANTI-COUNTERFEITING POLICY
                  </div>
                  <div className="privacy-title font-semibold">
                    GENERAL STATEMENT
                  </div>
                  <div className="privacy-content">
                    Your privacy and security is important to us. That is why
                    all the information you provide to us is kept safe and
                    secure in our web data servers. Your credit card details as
                    well as your identity is protected on our site by 128 – bit
                    SSL (secure sockets layer) encryption technology. We never
                    pass on your details to third parties except as part of our
                    providing services (i.e. when we ship your orders) or
                    compliance to legal or regulatory requirements, when
                    necessary.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    WHO MAY PROCESS DATA
                  </div>
                  <div className="privacy-content">
                    Your personal information (which includes your name, address
                    and any other details you provide to us which concern you as
                    an individual) may be processed both by us and by other
                    companies within our group. When you place an order via this
                    website, we will store your e-mail address along with any
                    other information you may provide. When you use the services
                    on the website, we may need to collect further information
                    about you. This information may include, but is not limited
                    to, details such as your name, address and your credit card
                    details. Some of those to whom we may transfer information
                    (as specified above) are located in the Philippines which at
                    present has not been designated as having an adequate system
                    to ensure data privacy in place.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    PURPOSE OF PROCESSING
                  </div>
                  <div className="privacy-content">
                    We will use your information for the purpose of providing
                    you with information about our services, articles and news,
                    fulfilling orders placed by you, processing any other
                    transactions authorised or made by you with us, informing
                    you of special offers and providing other marketing
                    information to you which we think you may find of interest,
                    undertaking product or customer research/development.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    DISCLOSURE OF INFORMATION
                  </div>
                  <div className="privacy-content">
                    In the unlikely event that a liquidator, administrator or
                    receiver is appointed over us or all or any part of our
                    assets that insolvency practitioner may transfer your
                    information to a third party purchaser of the business
                    provided that purchaser undertakes to use your information
                    for the same purposes as set out in this policy. Your
                    information will not be disclosed to government or local
                    authorities or other government institutions save as
                    required by law or other binding regulations.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">SECURITY</div>
                  <div className="privacy-content">
                    We have implemented security policies, rules and technical
                    measures to protect the personal data that we have under our
                    control from unauthorised access, improper use and
                    disclosure, unauthorised destruction or accidental loss.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">COPYRIGHT</div>
                  <div className="privacy-content">
                    All website design, text, graphics, the selection and
                    arrangement thereof are owned by the company running this
                    site, or hosting this site. ALL RIGHTS RESERVED.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">TRADEMARKS</div>
                  <div className="privacy-content">
                    All trademarks are the property of their respective owners
                    and may be registered in certain parts of the world.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    DISCLAIMER OF WARRANTY AND LIABILITY
                  </div>
                  <div className="privacy-content mb-3">
                    The following provisions may be curtailed or disallowed by
                    the laws of certain jurisdictions. In such cases, the terms
                    hereof are to be read as excluding or limiting such term so
                    as to satisfy such law. We do not represent or warrant that
                    the information accessible via this website is accurate,
                    complete or current. We have no liability whatsoever in
                    respect of any use which you make of such information.
                  </div>
                  <div className="privacy-content">
                    The information provided on this website has not been
                    written to meet your individual requirements and it is your
                    sole responsibility to satisfy yourself prior to ordering
                    any products or services from us that they are suitable for
                    your purposes. <br />
                    Whilst we make all reasonable attempts to exclude viruses
                    from the website, we cannot ensure such exclusion and no
                    liability is accepted for viruses. Thus, you are recommended
                    to take all appropriate safeguards before downloading
                    information or images from this website. <br />
                    All warranties, express or implied, statutory or otherwise
                    are hereby excluded. Neither we nor any of our employees or
                    affiliated entities will be liable for any kind of damages
                    and howsoever arising including, without limitation, loss of
                    profits, compensatory, consequential, direct, exemplary,
                    incidental, indirect, punitive or special, damages or any
                    liability which you may have to a third party, even if we
                    have been advised of the possibility of such loss. <br />
                    We are not responsible for the direct or indirect
                    consequences of you linking to any other website from this
                    website.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    CONSENT AND ENQUIRIES
                  </div>
                  <div className="privacy-content">
                    By continuing to use this site and access the information on
                    this website, you signal acceptance of the terms and
                    disclaimer set out above. If you do not accept any of these
                    terms, leave this website now. If you have any enquiry or
                    concern about our privacy policy or the way in which we are
                    handling personal data, please contact us.
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }
}
