import { useState } from "react";
import ReactDOM from "react-dom/client";
import { Link } from "react-router-dom";
import { PrimaryMenu } from "../menus";
import style from "src/css/_header.module.scss";
import Landing from "src/pages/Home";
import { Fade } from "react-reveal";
import "./header.css";

const Header = (props) => {
  const [activeIndex, setActiveIndex] = useState(5);
  // ** Props
  const { handleBlur, handleFocus, location } = props;
  const [isNavOpen, setIsNavOpen] = useState(false);

  const listItems = PrimaryMenu.map((items, i) => {
    return (
      <li className={style.menuItem} key={items.label}>
        <a
          className={
            activeIndex === i
              ? "active-nav no-underline"
              : "inactive-nav no-underline"
          }
          href={items.path}
          onClick={() => setActiveIndex(i)}
        >
          {items.label}
        </a>
      </li>
    );
  });

  const mobileListItems = PrimaryMenu.map((items) => {
    return (
      <li className="border-b border-gray-400 my-5 uppercase" key={items.label}>
        <a href={items.path}>{items.label}</a>
      </li>
    );
  });

  return (
    <header className="absolute z-20 w-full h-full overflow-hidden">
      <div className=" __header-wrapper relative flex justify-between items-center after:-content-[''] after:absolute after:-width-[100%] after:h-[1px] after:-top-1/2 after:-left-[10%] after:bg-white after:-translate-y-[-50%]">
        <div className="brand flex items-center relative md:ml-10 ml-6">
          <div className="w-full absolute line-nav"></div>
          <Link to="/" aria-label="Home" onClick={() => setActiveIndex(4)}>
            <img
              src="/image/logo.png"
              //   width={150}
              alt=""
              className="max-w-full header-logo"
            />
          </Link>
        </div>
        <nav className="__navigation pb-[11px]">
          {/* desktop nav */}
          <div className="flex justify-between items-center md:justify-end gap-6 top-[31%] absolute right-[5%]">
            <ul className={`hidden no-underline lg:flex ${style.menu}`}>
              {listItems}
            </ul>
          </div>
          {/* mobile nav */}
          <section
            className={
              isNavOpen
                ? "flex justify-end lg:hidden sm:mx-0 h-[100vh]"
                : "flex justify-end lg:hidden sm:mx-0"
            }
          >
            {/* hamburger menu */}
            <div
              className="space-y-2 md:mr-10 mr-6"
              onClick={() => setIsNavOpen((prev) => !prev)}
            >
              <span className="block h-0.5 w-8 animate-pulse bg-[#ffffff]"></span>
              <span className="block h-0.5 w-8 animate-pulse bg-[#ffffff]"></span>
              <span className="block h-0.5 w-8 animate-pulse bg-[#ffffff]"></span>
            </div>

            <div
              className={
                isNavOpen
                  ? "showMenuNav transition transition-opacity ease-in-out delay-150"
                  : "hideMenuNav "
              }
            >
              <Fade duration={500}>
                {/* <div className=" shrink-0 z-20">
                  <img
                    src="/img/hubspot-partner-logo.png"
                    width={90}
                    alt=""
                    className="max-w-full"
                  />
                </div> */}
                {/* cross icon */}
                <div
                  className="absolute top-0 right-0 px-8 py-8"
                  onClick={() => setIsNavOpen((prev) => !prev)}
                >
                  <svg
                    className="h-8 w-8 text-gray-600"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </div>
                <ul className="MENU-LINK-MOBILE-OPEN flex flex-col items-center justify-between">
                  {mobileListItems}
                </ul>
              </Fade>
            </div>
          </section>
        </nav>
      </div>
      <div className="mt-[10px] w-full md:ml-10 ml-6" id="mobile_carousel_header">
        <h2 className="text-left title text-white md:text-4xl text-sm leading-[1.5rem] font-black font-semibold ipad-text">
          Redefine Experiences Through <br class="md:block hidden" />
          Transformative Spaces
        </h2>
      </div>
    </header>
  );
};

export default Header;
