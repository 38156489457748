import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import ReactHtmlParser from "react-html-parser";
import { Fade, Bounce } from "react-awesome-reveal";

// import { Carousel } from "react-responsive-carousel";
import CloseBtn from "../../img/close-btn.png";
import Carousel from "react-bootstrap/Carousel";

import "bootstrap/dist/css/bootstrap.min.css";

const itemsbanner = [
  {
    id: 1,
    title:
      "Redefine Experiences Through <br class='md:block hidden' />Transformative Spaces",
    buttonText: "Start Growing My Brand",
    link: "#",
    image: "/image/carousel/1bb.png",
  },
  {
    id: 2,
    title:
      "Redefine Experiences Through <br class='md:block hidden' />Transformative Spaces",
    buttonText: "Start Converting My Customers",
    link: "#",
    image: "/image/carousel/2b.jpg",
  },
  {
    id: 3,
    title:
      "Redefine Experiences Through <br class='md:block hidden' />Transformative Spaces",
    buttonText: "Start Transforming My Audience",
    link: "#",
    image: "/image/carousel/3b.jpg",
  },
  {
    id: 4,
    title:
      "Redefine Experiences Through <br class='md:block hidden' />Transformative Spaces",
    buttonText: "Start Transforming My Audience",
    link: "#",
    image: "/image/carousel/4bb.png",
  },
];

const LandingCarousel = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="relative" id="mainContentStart">
        {/* <div className="md:w-3/4 w-4/5 md:mb-16 mb-5 absolute md:top-[22%] top-[40%] left-[0%] z-10 ipad-carousel">
          <div className="mx-10">
            <h2 className="text-left title text-white md:text-4xl text-sm leading-[1.5rem] font-black font-semibold ipad-text">
              Redefine Experiences Through <br class="md:block hidden" />
              Transformative Spaces
            </h2>
          </div>
        </div> */}
        <Fade>
          <Carousel controls={false} indicators={false}>
            {itemsbanner.map((e, i) => {
              return (
                <Carousel.Item interval={2500}>
                  <div className="group relative">
                    <div className="ipad-height">
                      <img
                        src={e.image}
                        className="max-w-full h-full  object-cover object-top"
                        alt=""
                      />
                    </div>
                    <div
                      className={`__carousel-content flex absolute top-0 left-0 w-full h-full items-center justify-center flex-col show`}
                    ></div>
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Fade>
      </div>
    </>
  );
};

export default LandingCarousel;
