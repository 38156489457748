import Home from 'src/pages/Home';
export const PrimaryMenu = [
    {
        label: 'ABOUT US',
        path: '#about'
    },
    {
        label: 'SERVICES',
        path: '#services'
    },
    {
        label: 'OUR WORKS',
        path: '#works'
    },
    {
        label: 'CONTACT US',
        path: '#contact'
    },
]

