import React, { useState, useEffect } from "react";
import Layout from "src/pages/Layout";
import Home from "src/pages/Home";
import Error404 from "src/pages/404";
import RouteProgress from "src/components/routes/RouteProgress";

import { BrowserRouter, Routes, Route } from "react-router-dom";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <RouteProgress />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="*" element={<Error404 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
