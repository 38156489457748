export default function Error404() {
    return (
        <div className="flex items-center h-[calc(100vh-366px)] justify-center flex-col">
          <div className="text-6xl text-center font-bold text-primary">404</div>
          <div className="text-2xl text-center mb-3">
            Ooops, page not found
          </div>
          <div className="text-xl text-gray text-center">
            We&apos;re sorry, we couldn&apos;t find the page you requested.
          </div>
        </div>
    )
}