import React, {useState, useEffect} from 'react';
import Banner from 'src/components/landing/Banner';
import About from 'src/components/landing/About';
import Services from 'src/components/landing/Services';
import Works from 'src/components/landing/Works';
import Contact from 'src/components/landing/ContactUs';
import { Fade, Bounce } from "react-awesome-reveal";

function Landing() {
  return (
    <div className="max-w-[1920px] block mx-auto">
      <div id="landingPage" >
        <Banner />
        <About />
        <Services />
        <Works/>
        <Contact/>
      </div>
    </div>
  
  );
}

export default Landing;

