import { useState, useEffect, React } from "react";
import {
  FaRegEnvelope,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";

import "./footer.css";

import TermModal from "./modal/TermsModal";
import PrivacyModal from "./modal/PrivacyModal";

const socials = [
  {
    title: "facebook",
    img: "/image/footer/socials/fb.png",
    src: "https://www.facebook.com/MoodboardSpace",
  },
  {
    title: "instagram",
    img: "/image/footer/socials/ig.png",
    src: "https://www.instagram.com/moodboard_space/",
  },
];

export default function Footer() {
  const [termModal, setTermModal] = useState();
  const [privacyModal, setPrivacyModal] = useState();

  const termClick = () => {
    setTermModal(true);
  };

  const privacyClick = () => {
    setPrivacyModal(true);
  };

  const closeModal = () => {
    setTermModal(false)
    setPrivacyModal(false)
    document.body.style.overflow = "initial"
  }


  return (
    <>
      <footer className="relative footer-container h-[35%] w-full flex items-center">
        <div className="md:px-[100px] px-10 my-5 w-full">
          <div className="flex flex-col">
            <div className="group">
              <div className="first-description">
                © 2023 moodboard. All Rights Reserved.
              </div>
            </div>
            <div className="group">
              <div className="uppercase first-subtitle pt-3">NYC:</div>
              <div className="first-description pb-3">
                321 East 66th Street, Unit 1D New York City 10065, New York
              </div>
              <div className="first-subtitle">Manila:</div>
              <div className="first-description pb-3">
                20/F Wynsum Corporate Plaza, F. Ortigas Jr. Road Ortigas Center
                1605 Pasig City
              </div>
              <div className="first-subtitle">Email:</div>
              <div className="first-description">inquiry@moodboard.space</div>
            </div>
          </div>
          <div className="w-full">
            <div className="flex justify-between md:mt-5 mt-2 items-center">
              <div className="first-description">
                <span className="flex"><div className="cursor-pointer mr-2" onClick={() => privacyClick()}>Privacy Policy</div> | <div className="cursor-pointer ml-2" onClick={() => termClick()}>Terms of Use</div></span>
              </div>
              <div className="flex md:gap-10 gap-3">
                {socials.map((e, i) => (
                  <a
                    href={e.src}
                    target="_blank"
                    className="w-6 h-6 flex justify-center items-center"
                  >
                    <img
                      src={e.img}
                      className="w-full h-full object-cover"
                      alt=""
                    />
                  </a>
                ))}
                <a href="mailto:inquiry@moodboard.space" target="_blank" className="w-6 h-6 flex justify-center items-center">
                  <img
                    src="/image/footer/socials/mail.png"
                    className="w-full h-full object-cover"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>

          {/* <div className="grid grid-cols-2">
            <div className="footer-first md:px-[80px]">
              <div className="first-title uppercase pt-2">contact info</div>
              <div className="uppercase first-subtitle pt-5">NYC:</div>
              <div className="first-description pb-5">
                321 East 66th Street, Unit 1D New York City 10065, New York
              </div>
              <div className="first-subtitle">Manila:</div>
              <div className="first-description pb-5">
                20/F Wynsum Corporate Plaza, F. Ortigas Jr. Road Ortigas Center
                1605 Pasig City
              </div>
            </div>
            <div className="footer-second md:px-[80px]">
              <ul className="mx-auto">
                <li className="uppercase p-2">
                  <a href="#about">ABOUT US</a>
                </li>
                <li className="uppercase p-2">
                  <a href="#services">SERVICES</a>
                </li>
                <li className="uppercase p-2">
                  <a href="#works">OUR WORKS</a>
                </li>
                <li className="uppercase p-2">
                  <a href="#contact">CONTACT US</a>
                </li>
              </ul>
            </div>
            <div className="footer-third md:px-[80px] p-2">
              <div className="first-subtitle">Email:</div>
              <div className="first-description">inquiry@moodboard.space</div>
            </div>
            <div className="footer-fourth grid grid-cols-4">
              <a
                className="footer-instagram grid items-center"
                href=" https://www.instagram.com/moodboard_space/"
                target="_blank"
              >
                <span className="mx-auto my-4">INSTAGRAM</span>
              </a>
              <a
                className="footer-facebook grid items-center"
                href="https://www.facebook.com/MoodboardSpace"
                target="_blank"
              >
                <span className="mx-auto">FACEBOOK</span>
              </a>
            </div>
          </div> */}
        </div>
      </footer>
      {termModal && (
        <TermModal
          show={termModal}
          close={closeModal}
        />
      )}
      {privacyModal && (
        <PrivacyModal
          show={privacyModal}
          close={closeModal}
        />
      )}
    </>
  );
}
