import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import ReactHtmlParser from "react-html-parser";
import { Fade, Bounce } from "react-awesome-reveal";

import { Carousel } from "react-responsive-carousel";
import CloseBtn from "../../img/close-btn.png";

import "./WorkModal.css";

const customStyles = {
  content: {
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // minWidth: '768px',
    // minHeight: '90vh',
    // overflow: 'hidden',
    padding: "0",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.85)",
    zIndex: 1050,
  },
};

Modal.setAppElement("#root");

export default class WorkWithUsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: true,
      loading: false,
      formModalOpen: false,
    };
    // this.OpenModal = this.OpenModal.bind(this);
    // this.CloseModal = this.CloseModal.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);
    this.handleFormDisplay = this.handleFormDisplay.bind(this);
  }

  // componentDidMount() {
  //   this.state.selected = JSON.stringify(this.props.selected)
  //   console.log(this.state.selected)
  // }

  modalIsOpen = true ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'initial';

  // OpenModal() {
  //   this.setState({modalIsOpen:true})
  // }
  // CloseModal() {
  //   this.setState({modalIsOpen: false})
  // }
  toggleLoader() {
    this.setState({ loading: this.state.loading ? false : true });
  }
  setIsFormDisplayed() {
    this.setState({ loading: this.state.loading ? false : true });
  }
  handleFormDisplay() {
    this.setState({ formModalOpen: true });
  }

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.show}
          style={customStyles}
          className="bg-[#472F1E] max-w-[100vw] m-auto max-h-[100vh] md:absolute relative md:top-1/2 md:left-1/2 md:-translate-x-[50%] md:-translate-y-[50%] md:p-10 p-6 outline-none"
        >
          <div className="flex justify-end h-full">
            <button
              className="z-20 privacy-modal-close-btn relative text-white md:mt-10 mt-3 md:-mb-10 mb-0 md:mr-10 mr-3"
              onClick={this.props.close}
            >
              x
            </button>
          </div>
          <Fade>
            <div className="relative h-[100vh] w-[100vw] text-white text-sm">
              <div className="container inter-font md:pt-10 md:pb-10 pb-[150px] overflow-scroll h-full">
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    TERMS & CONDITION
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    MODE OF PAYMENT
                  </div>
                  <div className="privacy-content mb-3">
                    1. PAYPAL <br />
                    Payments should be made through Paypal. From our checkout
                    page, you will be redirected to the PayPal website to
                    process your payment for payments@moodboard.ph. Afterwards,
                    you will receive a payment confirmation through email from
                    PayPal.
                  </div>
                  <div className="privacy-content mb-3">
                    2. BANK DEPOSIT <br />
                    You may also send your payment to our designated bank
                    account after you have placed and confirmed your order(s).
                    <br />
                    NOTE: You will need to pay for your order within 48 hours
                    and email at payments@moodboard.ph, a scanned copy of your
                    Deposit Slip along with your Name, Order Number, Mobile
                    Number, Landline Number, and Delivery Address so we can
                    process your order.
                  </div>
                  <div className="privacy-content mb-3">
                    Moodboard Manila Bank account details: <br />
                    Bank: METROPOLITAN BANK & TRUST CO. (METROBANK) <br />
                    Account Name: Moodboard Manila Inc. <br />
                    Account Number: 2327232523332
                  </div>
                  <div className="privacy-content">
                    3. CASH ON DELIVERY <br />
                    You may also choose Cash on Delivery as a Payment Option in
                    the Checkout page. However, this option is currently
                    available for Metro Manila purchases only.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    DELIVERY CHARGES AND TRANSIT TIME
                  </div>
                  <div className="privacy-content">
                    After your payment has been confirmed, you will receive your
                    order(s) within: <br />
                    Indent orders – 30 calendar days <br />
                    In stock items – 3-7 business days <br />
                    On Sale – 3-7 business days <br />
                    Costs of shipping will be computed based on weight/volume.
                    You can also decide to pickup your item at our Manila
                    office: 20/F Wynsum Corporate Plaza, F. Ortigas Jr. Rd.
                    Ortigas Center, Pasig City 1605. <br />
                    *Please indicate if you wish to have your sales invoice
                    shipped along with your order or emailed to you instead,
                    especially in cases where it will be delivered to an address
                    other than yours.
                  </div>
                </div>
                <div className="group">
                  <div className="privacy-title font-semibold">
                    POLICY ON SOLD OUT PRODUCTS
                  </div>
                  <div className="privacy-content mb-3">
                    We make it a point that all our pages in the shopping cart
                    are up-to-date in real time, in showing when items are still
                    available or sold out. However, if an item is not
                    available/sold out at the time of your order, just place a
                    backorder for the item, and as soon as the product becomes
                    available, we will notify you, so you can decide to push
                    through with your order.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    POLICY ON RETURNS AND REFUNDS
                  </div>
                  <div className="privacy-content">
                    1. If for some reason you are not satisfied with your
                    purchase, please send us an email at
                    customercare@moodboard.ph to validate your claim for return
                    or refund. Please note that items returned beyond ten (10)
                    calendar days from the initial shipping date will no longer
                    be honored.
                  </div>
                  <div className="privacy-content">
                    2. If the item gets damaged within thirty (30) days from the
                    initial shipping date**, you can choose to mail the item
                    back to us and we will forward the defective item to the
                    outlet partner abroad for repair, at your cost. Please note
                    that items for repair beyond thirty (30) days (weekends
                    included) from the initial shipping date will no longer be
                    honored. Customer is responsible for paying for the shipping
                    fee for items to be sent back to us.
                  </div>
                  <div className="privacy-content">
                    3. Items Not Eligible for Return: <br />
                    The following will be sent back to you, at your cost, given
                    the following conditions: <br />
                    3.1. Any product not purchased from directly from Moodboard
                    Manila but sold elsewhere, <br />
                    3.2. Any product that exhibits physical or electrical damage
                    due to customer negligence, <br />
                    3.3. Any product with missing, damaged, altered parts,
                  </div>
                  <div className="privacy-content">
                    4. Any product that is returned without all of its original
                    packaging and accessories, including the retail box,
                    manuals, cables, adaptors, and all other items originally
                    included with the product, and/or.
                  </div>
                  <div className="privacy-content">
                    5. Any product from which the posted code has been
                    removed/tampered from its packaging.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    ACCEPTANCE OF TERMS AND CONDITIONS AND AMENDMENTS
                  </div>
                  <div className="privacy-content">
                    MONEY TRANSFER/REMITTANCE You may send your payment via any
                    of the following channels: LBC, JRS, Air 21, Money Gram,
                    Western Union, etc. or other institutions that accept money
                    transfer/remittance. We will be more than glad to provide
                    you the payment details after you have placed and confirmed
                    your order(s). NOTE : You will need to pay for your order
                    within 48 hours and email at payments@moodboard.ph a scanned
                    copy of your Transaction Slip/Official Receipt along with
                    your Name, Order Number, Mobile Number, Landline Number and
                    Delivery Address so we can process your order. Each time you
                    use or access this website, you agree to be bound by these
                    Terms and Conditions, as amended from time to time with or
                    without notice to you. In addition, if you are purchasing a
                    particular product or service through this website, you will
                    be subject to any rules or guidelines applicable to those
                    products or services and they shall be incorporated by
                    reference into these Terms and Conditions. Please see our
                    Anti Counterfeiting Policy, which is incorporated into these
                    Terms and Conditions by reference.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    OUR WEBSITE, PRODUCTS, AND SERVICES
                  </div>
                  <div className="privacy-content">
                    Our website, products, and services provided to you on and
                    through our website is on an “as is” basis. You agree that
                    the owners of this website exclusively reserve the right and
                    may, at any time and without notice and any liability to
                    you, modify or discontinue this website and its products or
                    services or delete the data you provide, whether temporarily
                    or permanently. We shall have no responsibility or liability
                    for the timeliness, deletion, failure to store, inaccuracy
                    or improper delivery of any data or information.
                  </div>
                </div>
                <div className="group mb-3">
                  <div className="privacy-title font-semibold">
                    YOUR CUSTOMS RESPONSIBILITIES AND REGISTRATION OBLIGATIONS
                  </div>
                  <div className="privacy-content">
                    You agree to provide truthful information when requested for
                    services that need registration. When registering, you
                    explicitly agree to our Terms and Conditions, which may be
                    modified by us from time to time and available here. Charges
                    in excess of allowable limit and/or for bulk purchases are
                    for the account of the buyer. Import charges include certain
                    fees, such as export classification, importation risk
                    premium, and variance fees. These fees are in addition to
                    any custom duties and taxes imposed by the country tax and
                    customs officials.
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }
}
