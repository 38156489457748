import { React, useState } from "react";
import SubscriptionForm1 from "../forms/SubscriptionForm2";
// import img from '../../img/services/_CX-Driven-Social-Media-Website-Digital-Services.jpg'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Fade, Bounce } from "react-awesome-reveal";
import { BsGlobe2 } from "react-icons/bs";
import NotifModal from "../modal/NotifModal";
import Image from "../../img/contact/contact3.png";
import "./contact.css";
import axios from "axios";
import Footer from "./../Footer";

export default function ContactUs() {
  const [notif, setNotif] = useState(false);

  const apiSite = `site`;
  const handJoinSubmit = (e) => {
    e.preventDefault();

    const name = document.getElementById("name_form");
    const email = document.getElementById("email_form");
    const contact = document.getElementById("contact_form");
    const message = document.getElementById("message_form");

    const formData = new FormData();
    formData.append("name", name.value);
    formData.append("email", email.value);
    formData.append("contact", contact.value);
    formData.append("message", message.value);
    console.log(formData);
    // const apiSite = "https://api-staging.moodboard.space/api/email-store";
    // const apiSite = "http://127.0.0.1:8000/api/email-store";
    const apiSite = "https://api.moodboard.space/api/email-store";
    axios.post(apiSite, formData).then((response) => {
      console.log(response);
    });
    name.value = "";
    email.value = "";
    contact.value = "";
    message.value = "";

    setNotif(true);
  };

  return (
    <>
      <section id="contact" className="relative md:h-[100vh]">
        <img
          src={Image}
          alt=""
          className="w-full h-full absolute contact-img object-cover object-top"
        />
        <div className="md:p-2 p-3 h-[65%]">
          <div className="form-header mx-auto md:py-1 py-2 md:w-[69%] w-[95%]">
            Our&nbsp;<b className="bold-contact">moodboard</b>&nbsp;curator
            will assist you in creating your ideal space. Enhance your home with
            picturesque decor sourced from global boutiques and renowned stores.
            Let us prioritize your unique lifestyle and strive to elevate your
            everyday living.
          </div>
          <div className="form-container flex justify-center items-center h-[86%]">
            <form
              action=""
              className="grid grid-cols-1 text-center form-content pt-4"
              onSubmit={handJoinSubmit}
            >
              <span className="work-title">CONTACT US</span>
              <input
                type="text"
                name=""
                id="name_form"
                placeholder="NAME"
                className="form-input placeholder-white"
                required
              />
              <input
                type="email"
                name=""
                id="email_form"
                placeholder="EMAIL"
                className="form-input placeholder-white"
                required
              />
              <input
                type="text"
                name=""
                id="contact_form"
                placeholder="CONTACT NUMBER"
                className="form-input placeholder-white"
                required
              />
              <textarea
                name="message"
                id="message_form"
                className="form-message text-center placeholder-white"
                placeholder="MESSAGE"
                required
              ></textarea>
              <button className="my-2 border border-light w-40 mx-auto py-2 contact-submit">
                SUBMIT
              </button>
              <div className="my-2 md:text-3xl sm:text-xl text-lg font-playfair-italic">
                <h2 className="font-semibold">Design your experience now!</h2>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </section>
      <NotifModal show={notif} dismiss={() => setNotif(false)} />
    </>
  );
}
