import { useState } from "react";
import Vimeo from "@u-wave/react-vimeo";

const VimeoPlayer = (props) => {
    const [isVideoPaused, setPauseVideo] = useState(false);
    const attr = { ...props };

    // window.onscroll = function () { onScroll() };

    function onScroll() {
        // This reads if section has video
        document.querySelectorAll('section').forEach(function(el) {
            var video = el.querySelector('.video-container')
      
            if (video === null)
              return;
      
            var topNav = document.getElementById('topMost').offsetHeight;
            var movEl = el.offsetTop;
            var res = movEl - document.documentElement.scrollTop - (topNav + 200); // add 200 offset so that it will play in advace the video

            if (res <= 0) {
                console.log(res);
                setPauseVideo(false);
            }
    
        });
    }



    return (
        <Vimeo 
            id={attr.id}
            video={attr.video}
            controls={attr.controls}
            loop={attr.loop}
            responsive={attr.responsive}
            paused={isVideoPaused}
            playsInline={attr.playsInline}
            muted={attr.muted}
            autoplay={attr.autoplay}
            quality={attr.quality}
            width={attr.width}
            height={attr.height}
            className={attr.className}
            background={attr.background}
            transparent={attr.transparent}
        />
    );
}


export default VimeoPlayer;