import React, { useState, useEffect, useRef } from "react";
import {
  Fade,
  Bounce,
  Hinge,
  JackInTheBox,
  Roll,
  Rotate,
  Slide,
  Zoom,
} from "react-awesome-reveal";
import ReactHtmlParser from "react-html-parser";
import "./services.css";
import Vimeo from "src/components/vimeo";

const items = [
  {
    title:
      "Redefine experiences through <br class='md:block hidden' />transformative spaces",
    buttonText: "Start Growing My Brand",
    link: "#",
    // src: "https://player.vimeo.com/progressive_redirect/playback/865835419/rendition/1080p/file.mp4?loc=external&signature=31fc34eba593eb26e02d8ec4b3ae3c55fe55974081c0ad8a7a9a529c2876e3ec",
    // src: "871366663",
    src: "/image/service/11.png",
    // src: "https://player.vimeo.com/video/865835419",
  },
  {
    title:
      "Redefine experiences through <br class='md:block hidden' />transformative spaces",
    buttonText: "Start Transforming My Audience",
    link: "#",
    // src: "https://player.vimeo.com/progressive_redirect/playback/865834825/rendition/1080p/file.mp4?loc=external&signature=31fc34eba593eb26e02d8ec4b3ae3c55fe55974081c0ad8a7a9a529c2876e3ec",
    // src: "871366264",
    src: "/image/service/22.png",
    // src: "https://player.vimeo.com/video/865834825",
  },
  {
    title:
      "Redefine experiences through <br class='md:block hidden' />transformative spaces",
    buttonText: "Start Transforming My Audience",
    link: "#",
    // src: "https://player.vimeo.com/video/865833954",
    // src: "https://player.vimeo.com/progressive_redirect/playback/865833954/rendition/1080p/file.mp4?loc=external&signature=31fc34eba593eb26e02d8ec4b3ae3c55fe55974081c0ad8a7a9a529c2876e3ec",
    // src: "871367054",
    src: "/image/service/33.png",
  },
];

const LandingCarousel = () => {
  // ** Refs
  const carouselLink = useRef(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const [showContent, setShowContent] = useState(false);
  const [carouselActive, setCarouselActive] = useState(false);

  let currIndex = activeIndex ? activeIndex : 0;

  const prev = () => {
    currIndex--;

    var carouselItems = document.querySelectorAll(
      ".carouselservice .__carousel-item"
    );
    if (currIndex < 0) {
      console.log("enter");
      currIndex = carouselItems.length - 1;
    }

    loadImage(currIndex);
  };
  const next = () => {
    currIndex++;

    var carouselItems = document.querySelectorAll(
      ".carouselservice .__carousel-item"
    );
    if (currIndex > carouselItems.length - 1) {
      currIndex = 0;
    }

    if (currIndex < 0) {
      currIndex = carouselItems.length - 1;
    }

    // console.log(currIndex);
    loadImage(currIndex);
  };

  const loadImage = (x) => {
    const image = new Image();
    image.onload = function () {};
    image.src = "";

    setActiveIndex(x);
    setShowContent(false);

    setTimeout(() => {}, 0);
  };

  const handleBlur = (e) => {
    setCarouselActive(false);
  };

  const handleFocus = (e) => {
    const currentTarget = e.currentTarget;
    if (currentTarget === document.activeElement) {
      setCarouselActive(true);
    }
  };

  useEffect(() => {
    // console.log(activeIndex);
    setTimeout(() => {
      setShowContent(true);
    }, 1000);
  }, [activeIndex]);

  useEffect(() => {
    const close = (e) => {
      if (carouselActive) {
        if (e.key === "ArrowRight") {
          next();
        } else if (e.key === "ArrowLeft") {
          prev();
        }
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [carouselActive]);

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log("This will run every second!");
      next();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative md:py-10 md:h-[100vh] py-3 ipad-height-service" id="services">
      <div className="text-sm font-bold text-center text-[#472F1E] work-title">
        SERVICES
      </div>
      <ul class="control-dots-service flex justify-center md:pt-5 pt-2 ipad-service-text items-center pl-0">
        <li
          className={activeIndex === 0 ? "active-text" : ""}
          value="0"
          role="button"
          tabindex="0"
          aria-label="slide item 1"
          data-slide-to="1"
          onClick={() => loadImage(0)}
        >
          <h2 className="md:text-2xl text-[11px] md:mx-10 mx-0 font-semibold">
            {" "}
            Space Design & Execution
          </h2>
        </li>
        <span className="md:text-2xl text-[11px] text-[#a89c93] mb-2">/</span>
        <li
          className={activeIndex === 1 ? "active-text" : ""}
          value="1"
          role="button"
          tabindex="0"
          aria-label="slide item 2"
          data-slide-to="2"
          onClick={() => loadImage(1)}
        >
          <h2 className="md:text-2xl text-[11px] md:mx-10 mx-0 font-semibold">
            Event Styling
          </h2>
        </li>
        <span className="md:text-2xl text-[11px] text-[#a89c93] mb-2">/</span>
        <li
          className={activeIndex === 2 ? "active-text" : ""}
          value="2"
          role="button"
          tabindex="0"
          aria-label="slide item 3"
          data-slide-to="3"
          onClick={() => loadImage(2)}
        >
          <h2 className="md:text-2xl text-[11px] md:mx-10 mx-0 font-semibold">
            Space Consultancy
          </h2>
        </li>
      </ul>
      <div className="carouselservice">
        <div className="__carousel-inner">
          {items.map((e, i) => {
            return (
              <div
                className={`__carousel-item ${
                  activeIndex === i ? "active" : ""
                }`}
                key={e.title}
              >
                {/* import { Fade, Bounce, Hinge, JackInTheBox, Roll, Rotate, Slide, Zoom } from "react-awesome-reveal"; */}
                <Slide delay={3} damping={0} direction="right" triggerOnce={true}>
                  <div className="group relative">
                    <Fade delay={1} >
                      <div className="relative ceVideo-wrapper">
                        <div className="h-[90%] w-auto ipad-height-service flex items-center">
                          <img
                            src={e.src}
                            alt=""
                            className="mx-auto w-auto h-full"
                          />
                        </div>
                        {/* <Vimeo
                        id="ceVideo"
                        video={e.src}
                        controls={false}
                        loop={true}
                        responsive={true}
                        playsInline={true}
                        muted={true}
                        autoplay={true}
                        quality="720p"
                        fullscreen={false}
                        transparent={true}
                        background={true}
                        className="w-full h-full"
                      /> */}
                      </div>
                    </Fade>
                  </div>
                </Slide>
              </div>
            );
          })}
        </div>

        <ol className="__carousel-indicator">
          <li
            className={activeIndex === 0 ? "active" : ""}
            data-slide-to="1"
            onClick={() => loadImage(0)}
          ></li>
          <li
            className={activeIndex === 1 ? "active" : ""}
            data-slide-to="2"
            onClick={() => loadImage(1)}
          ></li>
          <li
            className={activeIndex === 2 ? "active" : ""}
            data-slide-to="3"
            onClick={() => loadImage(2)}
          ></li>
        </ol>
      </div>
    </div>
  );
};
export default LandingCarousel;
